import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Gaps in Employment",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "gaps-in-employment",
  "draft": false,
  "meta_title": "Gaps in Employment"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Explaining the gaps in your employment history can be both nerve-wracking and confusing for most people.`}</p>
    <p>{`As many Veterans decide to leave the Military and use additional vacation time to transition into their next career path, this additional gap combined with military service can prove confusing to potential employers too. Employers will sometimes make assumptions about candidates with a gap in their employment and often employers believe the best workers are the ones who already have work.`}</p>
    <p>{`But as with life, things happen. Unemployment happens, transitions happen, and in the end, honesty is always the best route. `}</p>
    <p>{`From family emergencies, illnesses, layoffs, and even luck, each person can be faced with the decision to work full-time or use their time and attention for something else. Overall, honesty will be your main champion on your resume and in your interview.`}</p>
    <p>{`First, let’s discuss your resume. Your resume is a brief overview of who you are and why a company should sign you up for an interview. Most recruiters are looking at your last two positions in less than 30 seconds to determine if you are a quick fit for the job. If this is where your gap in employment resides, it’s time to make sure your resume is set up to answer questions with clear and concise information. (Note: Purepost users can add the following information to your Civilian Job or Skills Sections.)`}</p>
    <ol>
      <li parentName="ol">{`Professional Experience versus Employment. An Employment section of a resume just tells the reader that you worked, but a Professional Experience section can cover a variety of experience and skills learned over time. This avoids you putting more than one sentence about why the gap is there if it works. Use your cover letter to explain any additional information if needed; your resume should include all the achievements and experiences in the past 10 years.`}</li>
      <li parentName="ol">{`Volunteer work. Add Volunteer positions into the Professional Experience section. Why? You either provided part-time work for free which the organization appreciated or you provided a skill they did not have but added to the organization's success. Talk to the organization about being your reference and the title you could list as your “job”. This could include PTA officer, Troop Leader, and Volunteer Coordinator if you gained experiences due to parenting or a personal break in employment.`}</li>
      <li parentName="ol">{`Stay Active and Share. Emphasize any activities you undertook during the gap to improve your professional standing. School, certifications, volunteering or major personal projects can be mentioned as well as consulting, freelance or contract work. The time you dedicated to a project will show your active learning ability. Additionally, mentoring and coaching peers and children should be noted to share your ability to be a part of another person’s development.`}</li>
      <li parentName="ol">{`Importance of the Skills Section: Having this at the top shows what you bring to the table regardless of gaps in time. Add years of skill experience to enhance that element if the company has it listed in the job description. For example: “10 Years Project Management”, “Bilingual- Spanish (Read/Write)”, or “2 Years Fundraising”. You can add additional Skills to your GuideOn resume by clicking on the Skills section and adding a new Skill at the bottom.`}</li>
    </ol>
    <p>{`Next up: How to discuss your gap in employment during your interview. Remember, you want to tell the hiring manager or recruiter why adding you to the team is the best option for both parties, don’t feel that you need to give all the details of your gap. Compare it to purchasing a quality used car. The goal is to find out how well the car will help you and be an asset to your life; not all the bad things it went through and how it might not work in one year due to a previous issue or change.`}</p>
    <ol>
      <li parentName="ol">{`Job cuts. Why are you no longer with your last employer? Identify if you aren’t at a company because of restructuring or downsizing. Those two words are important to why you no longer work at a company in a time where there are cutbacks, even in the Army.`}</li>
      <li parentName="ol">{`Your Choice to Leave. Explain your reason for leaving a company in a positive way during an interview. What were your achievements? What did you learn that you want to use in your new job?`}</li>
      <li parentName="ol">{`Focus on the future. Rather than dwelling on or apologizing for the break, you want to let the interviewer know that you are excited and ready to work. Be prepared to answer questions based on your previous jobs, experiences, and values. Provide positive, future-focused responses and be proud of the accomplishments you’ve had at a job, in school, when volunteering and in life.`}</li>
      <li parentName="ol">{`Be Honest. At the end of the day, just be honest if asked what you were doing during a gap in employment. How you verbally respond to the question and your physical behavior portraying confidence will allow the person to best understand why you were without a job for a specific period of time. `}</li>
    </ol>
    <p>{`With ever-changing career mobility and economic tides, gaps in employment are becoming more and more common. Don't let any taboo fears override your instincts to just be honest and explain periods of unemployment in your professional life. Honesty and confidence in your journey will be your best accompaniment in a resume and interview setting.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      